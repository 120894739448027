<template>
    <div class="template-page mission">
        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <div v-if="pageData.body!=undefined" v-html="this.pageData.body[0].processed"></div>
            <PubsList :worksdata="this.worksdata" />
        </basic-page>
    </div>
</template>

<script>
import { fetchSingleNode } from '../libs/drupalClient'
import BasicPage from './BasicPage.vue'
import PubsList from '../components/PubsList.vue'

export default {
  components: { BasicPage, PubsList },
    name: 'mission',
    data: () => {
        return {
            currentPageId: "117a6a87-d140-4f99-8c92-948b881835e9",
            pageData: {},
            fulldata: {},
            bannerimg: null,
            worksdata: []
        }
    },
    methods: { },
    computed: { },
    mounted() {

        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top','field_page_image', 'field_attachments'] })
            .then(res => {
                this.pageData = res[0].attributes
                this.fulldata = res[0]
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })

        fetch(process.env.VUE_APP_ENDPOINT + '/allpublications')
        .then(res => res.json())
        .then(json => {
            
            var data = json.publications

            data.forEach(w => {
                if(!Array.isArray(w) && w.length != 0) {
                    var found = false

                    this.worksdata.forEach(d => {
                        if(d.title == w.title)
                            found = true
                    })

                    if(!found)
                        this.worksdata.push(w)
                }
            })
        })

    }
          
}
</script>

<style lang="scss" scoped>
</style>